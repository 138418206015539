import qs from 'qs';
import Pagination from 'core/models/pagination';
import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';
import RelatorioViagem from 'core/models/FRO/relatorioViagem';
import api from '../api';

const baseURL = `/FRO/ColetaEmbarque`;

export const getColetaEmbarqueList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaEmbarque(json)) ?? [],
  };
};

export const getColetaEmbarquePagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaEmbarque(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getRelatorioViagemPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginedRelatorioViagem`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RelatorioViagem(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
export const getRelatorioBraskemPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPaginedRelatorioBraskem`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new RelatorioViagem(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getColetaEmbarque = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ColetaEmbarque(value) : {};
};

export const saveColetaEmbarque = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteColetaEmbarque = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const updateColetaEmbarqueFatura = async (data) => {
  const response = await api.post(
    `${baseURL}/UpdateColetaEmbarqueFatura`,
    data
  );
  return response.data ?? {};
};

export const SearchColetaEmbarqueRecebimento = async (data) => {
  const response = await api.post(
    `${baseURL}/SearchColetaEmbarqueRecebimento`,
    data
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const printColetaEmbarque = async (data) => {
  const response = await api.post(`${baseURL}/Print`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const printRelatorioViagem = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelatorioViagem`, {
    params: filters,
  });
  return response.data ?? {};
};
export const printRelatorioBraskem = async (filters) => {
  const response = await api.get(`${baseURL}/PrintRelatorioBraskem`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelRelatorioViagem = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelRelatorioViagem`, {
    params: filters,
  });
  return response.data ?? {};
};
export const excelRelatorioBraskem = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelRelatorioBraskem`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printCartaCorrecao = async (data) => {
  const response = await api.post(`${baseURL}/PrintCartaCorrecao`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const excelColetaEmbarque = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllColetaEmbarque = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const inutilizarCte = async (filters) => {
  const response = await api.get(`${baseURL}/InutilizarCte`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? false,
  };
};

export const anularCte = async (filters) => {
  const response = await api.get(`${baseURL}/AnularCte`, {
    params: filters,
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const calcularEmbarqueTributacao = async (filters) => {
  const response = await api.get(`${baseURL}/CalcularEmbarqueTributacao`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? false,
  };
};

export const corrigirCte = async (data) => {
  const response = await api.post(`${baseURL}/CorrigirCte`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? false,
  };
};

export const enviaComplemento = async (data) => {
  const response = await api.post(`${baseURL}/EnviaComplemento`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const transmitCte = async (data) => {
  const response = await api.post(`${baseURL}/Transmit`, data);

  const { status, messages, value } = response.data ?? {};

  return {
    status,
    messages,
    data: value,
  };
};

export const averbarCte = async (filters) => {
  const response = await api.get(`${baseURL}/AverbarCte`, {
    params: filters,
  });

  const { status, message, messages } = response.data ?? {};

  return {
    status,
    message,
    messages,
  };
};

export const verificarCte = async (filters) => {
  const response = await api.get(`${baseURL}/VerificarCte`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: new ColetaEmbarque(value ?? {}),
  };
};

export const envioEDI = async (filters) => {
  const response = await api.get(`${baseURL}/EnvioEDI`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const downloadCte = async (filters) => {
  const response = await api.get(`${baseURL}/DownloadCte`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const desvincularCteRef = async (data) => {
  const response = await api.post(`${baseURL}/DesvincularCteRef`, data);

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const enviaEmail = async (filters) => {
  const response = await api.get(`${baseURL}/EnviaEmail`, {
    params: filters,
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const getColetaEmbarqueAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaEmbarque(json)) ?? [],
  };
};

export const cancelCte = async (data) => {
  const response = await api.post(`${baseURL}/CancelCte`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const cancelamentoExtemporaneo = async (data) => {
  const response = await api.post(`${baseURL}/CancelamentoExtemporaneo`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const getColetaEmbarquePaginedFaturamentoPendente = async (filters) => {
  const response = await api.get(
    `${baseURL}/SearchPaginedFaturamentoPendente`,
    {
      params: filters,
      paramsSerializer: (params) => qs.stringify(params),
    }
  );
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaEmbarque(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const alteraProtocoloSeguro = async (data) => {
  const response = await api.post(`${baseURL}/AlteraProtocoloAverbacao`, data);

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const averbarLoteCtes = async (data) => {
  const response = await api.post(`${baseURL}/AverbarLoteCtes`, data);

  const { status, messages, value } = response.data ?? {};

  return {
    status,
    messages,
    data: value,
  };
};

export const solicitaContingencia = async (filters) => {
  const response = await api.get(`${baseURL}/SolicitaContingencia`, {
    params: filters,
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};
