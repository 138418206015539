/* eslint-disable import/extensions */
import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  getPocOrcamentoPagined,
  printList,
  printListEnergia,
  VerifyAllClickSignEnergia,
} from 'core/services/POC/pocOrcamento';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  Textbox,
  DatePicker,
  RadioButton,
  DropdownMulti,
  ToolbarButtons,
} from 'ui/components';

import { MaskTypes } from 'ui/Helpers/masks';

import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';

import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';

import { getPocOrcamentoStatusAutoComplete } from 'core/services/POC/pocOrcamentoStatus.tsx';

import { getParametro } from 'core/services/SEG/parametro';

import ModalImportaFaturaDeEnergia from './modalImportaFaturaEnergia';

const columns = [
  { key: 'nrSeqPocOrcamento', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdProposta', title: 'Código' },
  { key: 'nrOrdem', title: 'Revisão' },
  {
    key: 'tipoOrcamento.noPocTipoOrcamento',
    title: 'Tipo',
    sortKey: 'noPocTipoOrcamento',
  },
  {
    key: 'status.noPocOrcamentoStatus',
    title: 'Status',
    sortKey: 'noPocOrcamentoStatus',
    colorText: 'status.noColor',
  },

  {
    key: 'vendedor.noPessoa',
    title: 'Vendedor',
    sortKey: 'noVendedor',
  },
  {
    key: 'poco.cdPocPoco',
    title: 'Poço',
    sortKey: 'cdPocPoco',
  },
  {
    key: 'noLocalObra',
    title: 'Local da Obra',
    sortKey: 'noLocalObra',
  },
  {
    key: 'cliente.noPessoa',
    title: 'Cliente',
    sortKey: 'noPessoa',
  },
  { key: 'dtCad', title: 'Cadastro', format: GridView.DataTypes.Date },
  {
    key: 'dtCancelamento',
    title: 'Cancelamento',
    format: GridView.DataTypes.Date,
  },
  { key: 'dtValidade', title: 'Validade', format: GridView.DataTypes.Date },

  { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Decimal },
];

const columnsOrnEne = [
  { key: 'nrSeqPocOrcamento', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdProposta', title: 'Código' },
  { key: 'nrOrdem', title: 'Revisão' },
  {
    key: 'tipoOrcamento.noPocTipoOrcamento',
    title: 'Tipo',
    sortKey: 'noPocTipoOrcamento',
  },
  {
    key: 'status.noPocOrcamentoStatus',
    title: 'Status',
    sortKey: 'noPocOrcamentoStatus',
    colorText: 'status.noColor',
  },

  {
    key: 'vendedor.noPessoa',
    title: 'Vendedor',
    sortKey: 'noVendedor',
  },
  {
    key: 'cliente.noPessoa',
    title: 'Cliente',
    sortKey: 'noPessoa',
  },
  {
    key: 'energiaUnidadeConsumidora.nrUc',
    title: 'UC',
  },
  { key: 'dtCad', title: 'Cadastro', format: GridView.DataTypes.Date },
  {
    key: 'dtAprovadoCliente',
    title: 'Aprovado',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtEnvioAssinatura',
    title: 'Enviado',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtAssinado',
    title: 'Assinado',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtCancelamento',
    title: 'Cancelamento',
    format: GridView.DataTypes.Date,
  },
  { key: 'dtValidade', title: 'Validade', format: GridView.DataTypes.Date },
];

const situacaoOrcamento = [
  {
    text: 'Todos',
    value: 'T',
  },
  {
    text: 'Ativos ',
    value: 'A',
  },
  {
    text: 'Cancelados ',
    value: 'C',
  },
];

const tipoOrcamentos = [
  {
    text: 'Todos',
    value: 'T',
  },
  {
    text: 'Manutenção ',
    value: 'M',
  },
  {
    text: 'Perfuração ',
    value: 'P',
  },
];

export default function PocOrcamento({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
  user,
}) {
  const gridView = useRef();
  const dropDownCliente = useRef();

  const [filtros, setFiltros] = useState({
    flgSituacao: 'T',
    flgTipoOrcamento: 'T',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [isOrcEne, setIsOrcEne] = useState();
  const [importarFaturaModal, setImportarFaturaModal] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPocOrcamentoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  const onPrint = async () => {
    setLoading(true);

    let base64 = '';

    if (!isOrcEne) {
      const { value } = await printList(filtros);

      base64 = value;
    } else {
      const { value } = await printListEnergia(filtros);

      base64 = value;
    }

    onOpenReport(transaction.noTransacao, base64);
    setLoading(false);
  };

  const handleVerifyClickSignEnergia = async () => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await VerifyAllClickSignEnergia(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message:
          'Não há registros selecionados para a verificação de assinaturas.',
        theme: Theme.Warning,
      });
    }
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const parametro = await getParametro('ORC_ENERGIA');

      if (parametro.noConteudo) {
        setIsOrcEne(parametro.noConteudo === 'S');
        const dtAtual = new Date();
        filtros.dtCadInicio = new Date(dtAtual.setDate(dtAtual.getDate() - 15));
        filtros.dtCadFim = new Date();
      } else {
        setIsOrcEne(null);
      }

      const { data, pagination } = await getPocOrcamentoPagined(filtros);

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onSearchTipoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getPocTipoOrcamentoAutoComplete({
      noTipoOrcamento: e,
    });
    onSetMessage(status, msg);
    return tipos;
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onSearchStatusOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: statusOrcamento,
    } = await getPocOrcamentoStatusAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return statusOrcamento;
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgIncluirEndereco: false,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Orcamento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPocOrcamento = sourceRow.find(
      (e) => e.key === 'nrSeqPocOrcamento'
    );
    onOpenMaintenance(nrSeqPocOrcamento.value);
  };

  useEffect(() => {
    dropDownCliente.current.loadDataSource(onSearchCliente);
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Orçamento'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
      onPrint={onPrint}
    >
      <ToolbarButtons>
        {isOrcEne && (
          <>
            <ToolbarButtons.Button
              text='Importar fatura'
              icon='cloud-upload-alt'
              onClick={() => {
                setImportarFaturaModal(true);
                setMessage(null);
              }}
            />
            <ToolbarButtons.Button
              text='Verificar Assinatura dos Documentos'
              icon='search'
              onClick={() => handleVerifyClickSignEnergia()}
            />
          </>
        )}
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-4'>
          <DropdownMulti
            label='Cliente'
            ref={dropDownCliente}
            dataSourcePropertyText='noPessoa'
            dataSourcePropertyValue='nrSeqPessoaCli'
            selectedItems={selectedItems.cliente ?? []}
            onSelectItem={(cliente) => {
              setSelectedItems({ ...selectedItems, cliente });
              setFiltros({
                ...filtros,
                nrSeqPessoaCliS: cliente?.map((p) => p.nrSeqPessoaCli),
              });
            }}
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Tipo Orçamento'
            searchDataSource={onSearchTipoOrcamento}
            selectedItem={filtros.tipoOrcamento}
            onSelectItem={(tipoOrcamento) => {
              filtros.nrSeqPocTipoOrcamento = setFiltros({
                ...filtros,
                tipoOrcamento,
                nrSeqPocTipoOrcamento: tipoOrcamento.nrSeqPocTipoOrcamento,
              });
            }}
            dataSourceTextProperty='noPocTipoOrcamento'
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='Código Proposta'
            text={filtros.cdProposta}
            onChangedValue={(cdProposta) => {
              setFiltros({
                ...filtros,
                cdProposta,
              });
            }}
          />
        </div>

        <div className='col-2'>
          <Textbox
            label='Revisão'
            text={filtros.nrOrdem}
            onChangedValue={(nrOrdem) => {
              setFiltros({
                ...filtros,
                nrOrdem,
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Vendedor'
            searchDataSource={onSearchVendedor}
            selectedItem={filtros.vendedor}
            onSelectItem={(vendedor) => {
              setFiltros({
                ...filtros,
                vendedor,
                nrSeqPessoaVen: vendedor.nrSeqPessoa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Status Orçamento'
            searchDataSource={onSearchStatusOrcamento}
            selectedItem={filtros.status}
            onSelectItem={(status) => {
              setFiltros({
                ...filtros,
                status,
                nrSeqPocOrcamentoStatus: status.nrSeqPocOrcamentoStatus,
              });
            }}
            dataSourceTextProperty='noPocOrcamentoStatus'
          />
        </div>

        <div className='col-2 mb-3'>
          <DatePicker
            label='Validade Início'
            text={filtros.dtValidadeInicio}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtValidadeInicio) => {
              setFiltros({
                ...filtros,
                dtValidadeInicio,
              });
            }}
          />
        </div>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Validade Fim'
            text={filtros.dtValidadeFim}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtValidadeFim) => {
              setFiltros({
                ...filtros,
                dtValidadeFim,
              });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        {isOrcEne && (
          <>
            <div className='col-2'>
              <DatePicker
                label='Cadastrado de'
                text={filtros.dtCadInicio}
                onChange={(dtCadInicio) => {
                  setFiltros({
                    ...filtros,
                    dtCadInicio,
                  });
                }}
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Cadastrado até'
                text={filtros.dtCadFim}
                onChange={(dtCadFim) => {
                  setFiltros({
                    ...filtros,
                    dtCadFim,
                  });
                }}
              />
            </div>
          </>
        )}

        <div className='col-3'>
          <RadioButton
            outline
            label='Situação'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={situacaoOrcamento}
            selectedButton={filtros.flgSituacao}
            onChange={(flgSituacao) =>
              setFiltros({
                ...filtros,
                flgSituacao,
              })
            }
          />
        </div>

        {!isOrcEne && (
          <div className='col-3'>
            <RadioButton
              outline
              label='Tipo Orçamento'
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              buttons={tipoOrcamentos}
              selectedButton={filtros.flgTipoOrcamento}
              onChange={(flgTipoOrcamento) =>
                setFiltros({
                  ...filtros,
                  flgTipoOrcamento,
                })
              }
            />
          </div>
        )}
      </div>

      <div className='row'>
        {isOrcEne !== undefined && (
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={isOrcEne ? columnsOrnEne : columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              onRowDoubleClick={onRowDoubleClick}
              enableExcelExport
              transaction={transaction}
            />
          </div>
        )}
      </div>

      <div className='row'>
        <div className='col'>
          <ModalImportaFaturaDeEnergia
            isOpen={importarFaturaModal}
            setIsOpen={setImportarFaturaModal}
            afterImport={search}
            setCSDSelPageMessage={setMessage}
            nrSeqUsuarioLogado={user.nrSeqUsuario}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
